<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Plano Avançado</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
        <div>
     <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      </div>
        <b-card>
          <div class="form-style">
            <b-form-group>
              <b-form-group
                label="* Nome:"
                label-for="nested-document"
                label-align-sm="left"
                label-cols-sm="2"
                :state="validates.name"
                :invalid-feedback="advancedNameInvalidFeedback"
              >
                <b-form-input id="nested-name" v-model="user.name" ></b-form-input>
              </b-form-group>

              <b-form-group
                label="* Telefone:"
                label-for="nested-phone"
                label-align-sm="left"
                content-cols-lg="4"
                label-cols-sm="2"
                :state="validates.phone"
                :invalid-feedback="advancedPhoneInvalidFeedback"
              >
                <b-form-input class="col-sm-8" id="nested-phone" v-model="user.phone" v-mask="['(##) #####-####']"></b-form-input>
              </b-form-group>

              <b-form-group
                label="* Email:"
                label-for="nested-email"
                label-align-sm="left"
                content-cols-lg="4"
                label-cols-sm="2"
                :state="validates.email"
                :invalid-feedback="advancedEmailInvalidFeedback"
              >
                <b-form-input
                class="col-sm-8"
                id="nested-email"
                :state="null"
                v-model="user.email">
                </b-form-input>
              </b-form-group>

              <b-form-group
                label="* Empresa:"
                label-for="nested-company-name"
                label-align-sm="left"
                label-cols-sm="2"
                :state="validates.companyName"
                :invalid-feedback= "advancedCompanyNameInvalidFeedback"
              >
                <b-form-input id="nested-company-name" v-model="user.companyName"></b-form-input>
              </b-form-group>

              <b-form-group
                label="* Número de usuários:"
                label-for="nested-users-number"
                label-align-sm="left"
                content-cols-lg="4"
                label-cols-sm="2"
                :state="validates.usersNumber"
                :invalid-feedback="advancedNumberUserInvalidFeedback"
              >
                <b-form-input class="col-sm-8" id="nested-users-number" type="number" v-model="user.usersNumber"></b-form-input>
              </b-form-group>

              <b-form-group
                label="* Estimativa de horas por mês:"
                label-for="nested-hours-estimate"
                label-align-sm="left"
                content-cols-lg="4"
                label-cols-sm="2"
                :state="validates.hoursEstimate"
                :invalid-feedback="advancedHoursEstimateInvalidFeedback"
              >
                <b-form-input class="col-sm-8" id="nested-hours-estimate" type="number" v-model="user.hoursEstimate"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Observação:"
                label-for="nested-observation"
                label-align-sm="left"
                content-cols-lg="4"
                label-cols-sm="2"
                :state="validates.observation"
              >
                <b-textarea class="col-sm-12" id="nested-observation" v-model="user.observation"></b-textarea>
              </b-form-group>

              <div>
                <ul>
                  <li class="list-inline-item">
                    <b-button variant="primary" @click="onSendClickButton"
                      >Enviar</b-button
                    >
                  </li>
                </ul>
          </div>
            </b-form-group>
          </div>
    </b-card>
    </div>
    </div>
  </section>
</template>

<script>
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { storeService } from '../../services/store.service';
  import Loading from 'vue-loading-overlay';

  export default {
    components: {
      Loading
    },
    data () {
      return {
        isLoading: false,
        fullPage: true,
        user: {
          name: '',
          phone: '',
          email: '',
          companyName: '',
          usersNumber: '',
          hoursEstimate: '',
          observation: ''
        },
        validates: {
          name: '',
          phone: '',
          email: '',
          companyName: '',
          usersNumber: '',
          hoursEstimate: ''
        },
        advancedNameInvalidFeedback: 'Nome é necessário',
        advancedPhoneInvalidFeedback: 'Telefone é necessário',
        advancedCompanyNameInvalidFeedback: 'Nome é necessário',
        advancedEmailInvalidFeedback: 'E-mail é necessário',
        advancedNumberUserInvalidFeedback: 'Número de usuários é necessário',
        advancedHoursEstimateInvalidFeedback: 'Número de horas é necessário'
      };
    },
    watch: {
      'user.name' () {
        if (this.user.name === '') {
          this.advancedNameInvalidFeedback = 'Campo obrigatório';
        } else if (this.user.name.length <= 4) {
          this.advancedNameInvalidFeedback = 'Nome deve ter no mínimo 4 caracteres';
        }
        this.validates.name = this.user.name.length >= 4;
      },
      'user.phone' () {
        if (this.user.phone === '') {
          this.advancedPhoneInvalidFeedback = 'Campo obrigatório';
        } else if (this.user.phone.length < 14) {
          this.advancedPhoneInvalidFeedback = 'Telefone inválido';
        }
        this.validates.phone = this.user.phone.length === 15 || this.user.phone.length === 14;
      },
      'user.email' () {
        if (this.user.email === '') {
          this.advancedEmailInvalidFeedback = 'E-mail obrigatório';
        } else if (this.user.email.length < 6) {
          this.advancedEmailInvalidFeedback = 'E-mail inválido';
        }
        this.validates.email = this.validateEmail(this.user.email);
      },
      'user.companyName' () {
        if (this.user.companyName === '') {
          this.advancedCompanyNameInvalidFeedback = 'Campo obrigatório';
        } else if (this.user.companyName.length <= 4) {
          this.advancedCompanyNameInvalidFeedback = 'Nome deve ter no mínimo 4 caracteres';
        }
        this.validates.companyName = this.user.companyName.length >= 4;
      },
      'user.usersNumber' () {
        if (this.user.usersNumber === '') {
          this.advancedNumberUserInvalidFeedback = 'Campo obrigatório - apenas números';
        }
        this.validates.usersNumber = this.user.usersNumber >= 1;
      },
      'user.hoursEstimate' () {
        if (this.user.hoursEstimate === '') {
          this.advancedHoursEstimateInvalidFeedback = 'Campo obrigatório - apenas números';
        }
        this.validates.hoursEstimate = this.user.hoursEstimate >= 1;
      }
    },
    methods: {
      async onSendClickButton () {
        if (this.checkFormValidity()) {
          this.isLoading = true;
          const response = await storeService.processeAdvancedPurchase(this.user);
          this.Loading = false;
          if (response) {
            this.$toast.open('Aguarde o responsável da Dígitro entrar em contato!');
            setTimeout(() => {
              this.$router.push({ path: '/' });
            }, 2500);
          }
        }
      },
      checkFormValidity () {
        this.validates.name = this.user.name.length >= 4;
        this.validates.phone = this.user.phone.length === 15 || this.user.phone.length === 14;
        this.validates.email = this.validateEmail(this.user.email);
        this.validates.companyName = this.user.companyName.length >= 4;
        this.validates.usersNumber = this.user.usersNumber >= 1;
        this.validates.hoursEstimate = this.user.hoursEstimate !== '';

        return this.validates.name && this.validates.phone && this.validates.email && this.validates.companyName &&
          this.validates.usersNumber && this.validates.hoursEstimate;
      },
      validateEmail (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
    }
  };
</script>

<style lang='scss' scoped>

.box-body {
  border-top-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 50px;
  background-color: #e5e5e5;
}

.box-body .col-sm-12{
  padding-left: 0px;
  padding-right: 10px;
}

.bottom-bar {
  margin-top: 20px;
}

.section-title h2 {
  margin-left: 5px;
}

.form-style {
    margin-top: 25px;
    margin-left: 30px;
}

.card-body {
  font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    background-color: #FFFFFF;
    color: #6278A3;
    margin-left: 20px;
    font-family: Poppins;
}
 .list-container {
    margin-left: 20px !important;
    margin-right: 20px !important;
    flex: 1;
  }
  .card {
    background-color: #FFFFFF;
  }

  .list-inline-item {
    display: flex;
    justify-content: flex-end;
  }

</style>
